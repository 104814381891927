
<template lang="html">
    <div>
        <vx-card actionable class="cardx" >
            <vs-row vs-w="12">
                <vs-col vs-w="3"><h5>Stats By City</h5></vs-col>
                <vs-col vs-w="3"></vs-col>
                <vs-col vs-w="3"></vs-col>
                <vs-col vs-w="3">
                    <v-select label="countryName" v-model="selectedOption" :options="flaging_delays"></v-select>
                </vs-col>
            </vs-row><br>   
            <div>         
            <vs-table  max-items="10" :data="all">
                <template slot="thead" class="exc" slot-scope="{data}">
                    <vs-th >
                    Cities
                    </vs-th>
                    <vs-th >
                    M6
                    </vs-th>
                    <vs-th >
                    M5
                    </vs-th>
                    <vs-th>
                    M4
                    </vs-th>
                    <vs-th>
                    M3++
                    </vs-th>
                    <vs-th>
                    M3+
                    </vs-th>
                    <vs-th>
                    M3
                    </vs-th>
                    <vs-th>
                    M2
                    </vs-th>
                    <vs-th>
                    L5
                    </vs-th>
                    <vs-th>
                    L4
                    </vs-th>
                    <vs-th>
                    L4-
                    </vs-th>
                    <vs-th>
                    L3+
                    </vs-th>
                    <vs-th>
                    L3
                    </vs-th>
                    <vs-th>
                    L2
                    </vs-th>
                    <vs-th>
                    L2-
                    </vs-th>
                    <vs-th>
                    Total
                    </vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr  :key="indextr" v-for="(tr, indextr) in data" >
                        <vs-td  :data="data[indextr].cities" >
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].cities}}
                            </div>
                        </vs-td>
                        <vs-td  :data="data[indextr].M6" >
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].M6}}
                            </div>
                        </vs-td>  
                        <vs-td :data="data[indextr].M5">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].M5}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].M4">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].M4}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].M3plus">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].M3plus }}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].M3">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].M3}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].M2">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].M2}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].L5">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].L5}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].L4">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].L4}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].L4minus">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].L4minus}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].L3plus">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].L3plus}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].L3">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].L3}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].L2">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].L2}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].L2minus">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].L2minus}}
                            </div>
                        </vs-td>
                        <vs-td :data="data[indextr].Total">
                            <div @click="RouteStatsBde(data[indextr].cities)">
                                {{data[indextr].Total}}
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>  
            </div>
        </vx-card>
    </div>  
</template> 
<script>
    import vSelect from 'vue-select';
    export default {
        data:()=>({
        selectedOption: '',
        all: [],  
        database:[
            
            { "cities":"Bangalore",
                "M6": 124,
                "M5": 123,
                "M4": 189,
                "M3plus": 128,
                "M3": 523,
                "M2": 243,
                "L5": 456,
                "L4": 234,
                "L4minus": 26,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":123
            },
            {
                "cities":"Hyderabad",
                "M6": 234,
                "M5": 123,
                "M4": 160,
                "M3plus": 235,
                "M3": 234,
                "M2": 123,
                "L5": 123,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            }
        ],
        net_enquiry: [
            { "cities":"Bangalore",
                "M6": 124,
                "M5": 123,
                "M4": 234,
                "M3plus": 128,
                "M3": 134,
                "M2": 0,
                "L5": 0,
                "L4": 234,
                "L4minus": 26,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":123
            },
            {
                "cities":"Hyderabad",
                "M6": 452,
                "M5": 123,
                "M4": 160,
                "M3plus": 235,
                "M3": 234,
                "M2": 123,
                "L5": 0,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            }
        ],
        visitors: [
            { "cities":"Bangalore",
                "M6": 124,
                "M5": 547,
                "M4": 567,
                "M3plus": 128,
                "M3": 474,
                "M2": 125,
                "L5": 435,
                "L4": 234,
                "L4minus": 26,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":123
            },
            {
                "cities":"Hyderabad",
                "M6": 452,
                "M5": 123,
                "M4": 160,
                "M3plus": 235,
                "M3": 234,
                "M2": 123,
                "L5": 123,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            }
        ],
        eligibility:[{ "cities":"Bangalore",
                "M6": 234,
                "M5": 123,
                "M4": 234,
                "M3plus": 128,
                "M3": 456,
                "M2": 234,
                "L5": 234,
                "L4": 234,
                "L4minus": 26,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":23345
            },
            {
                "cities":"Hyderabad",
                "M6": 234,
                "M5": 123,
                "M4": 160,
                "M3plus": 235,
                "M3": 234,
                "M2": 234,
                "L5": 324,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            }],
        Loan: [
            { "cities":"Bangalore",
                "M6": 123,
                "M5": 234,
                "M4": 123,
                "M3plus": 235,
                "M3": 123,
                "M2": 234,
                "L5": 345,
                "L4": 132,
                "L4minus": 232,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":23345
            },
            {
                "cities":"Hyderabad",
                "M6": 234,
                "M5": 123,
                "M4": 160,
                "M3plus": 235,
                "M3": 234,
                "M2": 234,
                "L5": 324,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            }
        ],
        flagging_delays:[
            { "cities":"Bangalore",
                "M6": 234,
                "M5": 123,
                "M4": 983,
                "M3plus": 128,
                "M3": 1231,
                "M2": 125,
                "L5": 345,
                "L4": 234,
                "L4minus": 26,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":23345
            },
            {
                "cities":"Hyderabad",
                "M6": 234,
                "M5": 123,
                "M4": 160,
                "M3plus": 235,
                "M3": 234,
                "M2": 234,
                "L5": 324,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            }
        ],
        job_for_today:[
            { "cities":"Bangalore",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 223,
                "M2": 200,
                "L5": 60,
                "L4": 120,
                "L4minus": 1410,
                "L3plus": 119,
                "L3":112,
                "L2":218,
                "L2minus":5307,
                "Total":9381
            },
            {
                "cities":"Hyderabad",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 23,
                "M2": 200,
                "L5": 600,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            }
        ],
        i_am_waiting:[
            { "cities":"Bangalore",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 223,
                "M2": 200,
                "L5": 60,
                "L4": 120,
                "L4minus": 1410,
                "L3plus": 119,
                "L3":1121,
                "L2":2180,
                "L2minus":5307,
                "Total":9381
            }
        ],
        flaging_delays: [ "Database", "Net Enquiry", "Visitors", "Eligibility", "Loan", "Flagging Delays", "Job For Today", "I Am Waiting" ]
    }),
    mounted() {
        this.sortingMethod()
    },
    watch: {
        selectedOption: function(value) {
            this.all = []
            switch(value) {
                case 'Database':
                    this.database.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'Net Enquiry':
                    this.net_enquiry.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'Visitors':
                    this.visitors.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'Eligibility':
                    this.eligibility.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'Loan':
                    this.Loan.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'Flagging Delays':
                    this.flagging_delays.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'Job For Today':
                    this.job_for_today.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'I Am Waiting':
                    this.i_am_waiting.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                    
            }
        }
    },
    methods: {
        sortingMethod() {
            this.all = []
            switch(this.$store.state.StatByCity) {
                case 'database':
                    this.selectedOption = 'Database'
                    // console.log(this.database)
                    this.database.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'net_enquiry':
                    this.selectedOption = 'Net Enquiry'
                    this.net_enquiry.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'visitors':
                    this.selectedOption = 'Visitors'
                    this.visitors.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'eligibility':
                    this.selectedOption = 'Eligibility' 
                    this.eligibility.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'loan':
                    this.selectedOption = 'Loan'
                    this.Loan.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'flagging_delays':
                    this.selectedOption = 'Flagging Delays'
                    this.flagging_delays.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'job_for_today':
                    this.selectedOption = 'Job For Today'
                    this.job_for_today.forEach((base) => {
                        this.all.push(base)
                    })
                    break
                case 'iam_wating':
                    this.selectedOption = 'I Am Waiting'
                    this.i_am_waiting.forEach((base) => {
                        this.all.push(base)
                    })
                    break
            }
        },
        RouteStatsBde(type) {
            this.$store.commit('SET_STATS_BY_BDE_DETAILS', type)
            this.$router.push('/StatsByBde')
        }
    },
    components: {
        'v-select': vSelect,
        },
    }
</script>
<style>
/* .vs-radar-text{
    color: darkgray;
    position: relative;
    bottom: 70px;
    left: 35px;
    font-size: larger;
}
.radial_quarter{
    position: relative;
    height: 260px;
}
.lineAreaChartSpline{
    position: relative;
    height: 260px;
}
.vs-radar-thisquarter{
    color: darkgray;
    position: relative;
    bottom: 70px;
    font-size: larger;
    text-align: center;
}
.input-search{
      position: relative;
    right: 10px;
}
.table_heading{
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr{
  font-family: sans-serif;
  font-size: smaller;  
} */
</style>

